import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Define your role—supplier or specifier—and customize your profile details.`}</p>
    </PageDescription>
    <p>{`KOPE is designed to bring products and projects together. To do that, we need to know if you focus on supplying or specifying offsite solutions.`}</p>
    <p>{`Choose between `}<inlineCode parentName="p">{`I'm a supplier`}</inlineCode>{` / `}<inlineCode parentName="p">{`I'm a specifier`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/4_set-up-welcome-screen.png",
      "alt": "4_set-up-welcome-screen.png"
    }}></img>
    <p>{`If you are someone who designs, manufactures, sells or distributes the construction industry with offsite products, choose `}<inlineCode parentName="p">{`I'm a supplier`}</inlineCode>{`.`}</p>
    <p>{`Setting up your Supplier profile will allow you to host your products on your regional KOPE hub, connect with those specifying products and explore the possibilities of your product on construction projects. The more information you add, the better your and your specifier’s experiences will be on KOPE.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/5_set-up-supplier-profile-basic-information.png",
      "alt": "5_set-up-supplier-profile-basic-information.png"
    }}></img>
    <p>{`You can include information regarding the locations you serve, what sectors you are involved in and the categories your products would fall under.`}</p>
    <p>{` `}{` `}{`  ✔️ `}<em parentName="p">{`You can also add information such as your product certifications and detailed FAQs.`}</em></p>
    <p>{`With this complete, you now have a profile on your chosen KOPE hub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      